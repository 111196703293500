export function productsList() {
  document.addEventListener('DOMContentLoaded', function () {


    const productsContainerList = document.querySelectorAll('.productsContainerList');

    if (productsContainerList) {
      productsContainerList.forEach(listItem => {
        let currentPage = 1;
        const htmlEl = document.querySelector('html');
        const bodyEl = document.querySelector('body');
        const modalContainer = listItem.querySelector('.productsContainer--show');
        const modalContent = listItem.querySelector('.productsContainer__content');
        const productContainer = listItem.querySelector('.singleContainer');
        const productContent = listItem.querySelector('.singleContainer__content');
        const categoryId = modalContainer.dataset.categoryid;
        const categoryName = modalContainer.dataset.name;
        var totalProducts = 0;
        var productsLoaded = 0;
        let allProductsLoaded = false;
        const filterContainer = listItem.querySelector('.productsContainer__filters');
        let filters = [];
        const modalBackdrop = listItem.querySelector('.modalBackdrop');
        const loader = listItem.querySelector('.productsContainer__loading');
        const singleLoader = listItem.querySelector('.singleContainer__loading');
        const products = listItem.querySelectorAll('.product');
        const loadMoreButton = listItem.querySelector('.load-more-button');

        if(loadMoreButton) {
          loadMoreButton.addEventListener('click', function () {
            loadMoreProducts(categoryId, currentPage, filters);
          });
        }

        document.addEventListener('click', function(event) {
          const productElement = event.target.closest('.product');
        
          if(productElement !== null) {
            const productId = productElement.dataset.productid;

            showModal();
            fetchProductDetails(productId);
          }
        });

        const filterCheckboxes = filterContainer.querySelectorAll('input[type="checkbox"]');
        filterCheckboxes.forEach(function (checkbox) {
          checkbox.addEventListener('change', handleFilterCheckboxChange);
        });

        function handleFilterCheckboxChange(event) {
          const {
            value,
            checked
          } = event.target;

          if (checked) {
            filters.push(value);
          } else {
            const index = filters.indexOf(value);
            allProductsLoaded = false;
            if (index !== -1) {
              filters.splice(index, 1);
            }
          }
          currentPage = 1;
          allProductsLoaded = false;
          const loadMoreDiv = document.querySelector('.loadMoreWrapper');
          if (loadMoreDiv) {
            loadMoreDiv.remove();
          }
          fetchProducts(categoryId, currentPage, filters);
        }

        function fetchProducts(categoryId, currentPage, filters) {

          fetch(`/wp-json/krishome/v1/products/${categoryId}?page=${currentPage}&filter_tags=${filters.join(',')}`)
            .then(function (response) {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error('Błąd pobierania produktów');
              }
            })
            .then(function (products) {
              if (products.length > 0) {
                totalProducts = parseInt(products[0].total);
                productsLoaded = products.length;
              } else {
                totalProducts = 0;
                productsLoaded = 0;
              }
              showModal();
              modalContent.innerHTML = '';
              loader.classList.add('active');
              setTimeout(() => {
                displayProducts(products, categoryName, categoryId, filters);
                loader.classList.remove('active');
                modalBackdrop.classList.remove('active');
                htmlEl.classList.remove('overflow-hidden');
                bodyEl.classList.remove('overflow-hidden');
              }, 3000);

            })
            .catch(function (error) {
              console.error(error);
            });
        }

        function fetchProductDetails(productId) {
          fetch(`/wp-json/krishome/v1/product_details/${productId}`)
            .then(function (response) {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error('Błąd pobierania szczegółów produktu');
              }
            })
            .then(function (productDetails) {

              showProductDetailsModal();
              singleLoader.classList.add('active');
              setTimeout(() => {
                displayProductDetails(productDetails, categoryName);
                singleLoader.classList.remove('active');
              }, 3000);
            })
            .catch(function (error) {
              console.error(error);
            });
        }

        function displayProductDetails(productDetails, categoryName) {
          const productDetailsHTML = `
              <div class="singleContainer__details">
                  <p class="singleContainer__category">${categoryName}</p>
                  <h3 class="singleContainer__title">${productDetails.name}</h3>
                  <div class="singleContainer__image">
                  ${productDetails.is_new ? `
                        <div class="product__new">Nowość</div>
                        `
              : ''}
                      <img src="${productDetails.image}" alt="${productDetails.name}">
                  </div>
                  <div class="singleContainer__tabs">
                  ${productDetails.product_parameters_label && productDetails.other_product_informations_label ? `
                      <div class="singleContainer__tabHeader">
                          <div class="singleContainer__tabHeaderItem active" data-tab="parameters">${productDetails.product_parameters_label}</div>
                          <div class="singleContainer__tabHeaderItem" data-tab="other-informations">${productDetails.other_product_informations_label}</div>
                      </div>
                      `
              : ''} 
                      <div class="singleContainer__tabContent">
                          <div class="singleContainer__tabPane active" data-tab="parameters-pane">
                              <!-- Treść dla zakładki "Parametry" -->
                              ${generateProductParametersHTML(productDetails.product_parameters)}
                          </div>
                          <div class="singleContainer__tabPane" data-tab="other-informations-pane">
                              <!-- Treść dla zakładki "Inne informacje" -->
                              ${generateOtherInformationsHTML(productDetails.other_product_informations)}
                          </div>
                      </div>
                  </div>
              </div>
          `;
          productContent.innerHTML = productDetailsHTML;

          const tabHeaders = document.querySelectorAll('.singleContainer__tabHeaderItem');
          const tabPanes = document.querySelectorAll('.singleContainer__tabPane');
          const lines = document.querySelectorAll('.singleContainer__line--inside');

          tabHeaders.forEach(tabHeader => {
            tabHeader.addEventListener('click', () => {
              const selectedTab = tabHeader.getAttribute('data-tab');

              tabHeaders.forEach(header => header.classList.remove('active'));
              tabHeader.classList.add('active');

              tabPanes.forEach(pane => {
                if (pane.getAttribute('data-tab') === selectedTab + '-pane') {
                  pane.classList.add('active');
                } else {
                  pane.classList.remove('active');
                }
              });

              lines.forEach(line => {
                if (line.getAttribute('data-tab') === selectedTab) {
                  line.classList.add('active');
                } else {
                  line.classList.remove('active');
                }
              });
            });
          });
        }

        function generateOtherInformationsHTML(otherInformations) {
          if (!otherInformations || otherInformations.length === 0) {
            return '<p>Brak dostępnych informacji.</p>';
          }

          let informationsHTML = '';
          otherInformations.forEach(information => {
            informationsHTML += `<div class="singleContainer__singleTab"><span class="singleContainer__singleTab--left">${information.other_product_information}</span> <span class="singleContainer__singleTab--right">${information.other_product_information_description}</span></div>`;
          });

          return informationsHTML;
        }

        function showProductDetailsModal() {
          productContainer.style.display = 'block';
          htmlEl.classList.add('overflow-hidden');
          bodyEl.classList.add('overflow-hidden');
          const back = document.querySelector('.modalBack');

          setTimeout(function () {
            productContainer.classList.add('show');
            document.addEventListener('click', hideModal);
            back.addEventListener('click', hideProductModal);
          }, 300);
        }

        function handleProductClick(event) {
          const productElement = event.target.closest('.product');
          showModal();
          if (productElement) {
            const productId = productElement.dataset.productid;
            fetchProductDetails(productId);
          }
        }

        function hideModal(event) {
          let target = event.target;
          let isCloseButton = false;

          while (target !== document.body) {
            if (target.classList.contains('modalClose')) {
              isCloseButton = true;
              break;
            }
            target = target.parentNode;
          }
          if (!modalContainer.contains(event.target) && !productContainer.contains(event.target) || isCloseButton) {
            modalBackdrop.classList.remove('active');
            modalContainer.classList.remove('show');
            productContainer.classList.remove('show');
            htmlEl.classList.remove('overflow-hidden');
            bodyEl.classList.remove('overflow-hidden');
            filters = [];
            // const loadMoreDiv = document.querySelector('.loadMoreWrapper');
            setTimeout(function () {
              // modalContainer.style.display = 'none';
              productContainer.style.display = 'none';
              // modalContent.innerHTML = '';
              productContent.innerHTML = '';
              // if (loadMoreDiv) {
              //   loadMoreDiv.remove();
              // }

            }, 300);
          }
        }

        function showModal() {
          modalContainer.style.display = 'block';
          htmlEl.classList.add('overflow-hidden');
          bodyEl.classList.add('overflow-hidden');

          setTimeout(function () {
            modalContainer.classList.add('show');
            modalBackdrop.classList.add('active');
            document.addEventListener('click', hideModal);
          }, 300);
        }

        function hideProductModal() {
          productContainer.classList.remove('show');
          modalBackdrop.classList.remove('active');
          setTimeout(function () {
            productContainer.style.display = 'none';
            productContent.innerHTML = '';
          }, 300);
        }

        function generateProductParametersHTML(productParameters) {
          if (!productParameters || productParameters.length === 0) {
            return '<p>Brak dostępnych parametrów.</p>';
          }

          let parametersHTML = '';
          productParameters.forEach(parameter => {
            parametersHTML += `<div class="singleContainer__singleTab"><span class="singleContainer__singleTab--left">${parameter.product_parameter}</span> <span class="singleContainer__singleTab--right">${parameter.product_parameter_description}</span></div>`;
          });

          return parametersHTML;
        }

        function loadMoreProducts(categoryId, currentPage, filters) {
          currentPage++;
          fetch(`/wp-json/krishome/v1/products/${categoryId}?page=${currentPage}&filter_tags=${filters.join(',')}`)
            .then(function (response) {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error('Błąd pobierania produktów');
              }
            })
            .then(function (products) {
              appendProducts(products);
              productsLoaded += products.length;
            })
            .catch(function (error) {
              console.error(error);
            });
        }

        function displayProducts(products, categoryName, categoryId, filters) {
          let productsHTML = '';
          if (products.length > 0) {
            const modalTitle = modalContainer.querySelector('.productsContainer__title');
            modalTitle.textContent = categoryName;
            for (let i = 0; i < products.length; i++) {
              const product = products[i];
              const productHTML = `
                      <div class="product" data-productid="${product.id}">
                  ${product.is_new === true ? `
                        <div class="product__new">Nowość</div>
                        `
                  : ''}
                          <img src="${product.image}" alt="${product.name}" class="product-image">
                          <h3 class="product-name">${product.name}</h3>
                      </div>
                  `;
              productsHTML += productHTML;
            }
            modalContent.innerHTML = productsHTML;

            modalContent.removeEventListener('click', handleProductClick);
            modalContent.addEventListener('click', handleProductClick);


            if (products.length >= 9) {
              const loadMoreDiv = document.createElement('div');
              loadMoreDiv.classList.add('loadMoreWrapper');
              const loadMoreButton = document.createElement('button');
              loadMoreButton.textContent = 'Zobacz więcej';
              loadMoreButton.classList.add('load-more-button', 'button');
              loadMoreDiv.appendChild(loadMoreButton);
              modalContainer.appendChild(loadMoreDiv);
              loadMoreButton.addEventListener('click', function () {
                loadMoreProducts(categoryId, currentPage, filters);
              });
            } else {
              const loadMoreDiv = document.querySelector('.loadMoreWrapper');
              if (loadMoreDiv) {
                loadMoreDiv.remove();
              }
            }

            if (productsLoaded >= totalProducts) {
              allProductsLoaded = true;
            }
          } else {
            modalContent.innerHTML = '';
            const noProductsMessage = document.createElement('p');
            noProductsMessage.classList.add('emptyProducts');
            noProductsMessage.textContent = 'Brak produktów';
            modalContent.appendChild(noProductsMessage);
          }
        }

        function appendProducts(products) {
          let productsHTML = '';

          if (products.length > 0) {
            const loadMoreDiv = document.querySelector('.loadMoreWrapper');
            if (loadMoreDiv) {
              loadMoreDiv.remove();
            }

            for (let i = 0; i < products.length; i++) {
              const product = products[i];
              // console.log('2', product)
              const productHTML = `
                <div class="product" data-productid="${product.id}">
                ${product.is_new === true ? `
                        <div class="product__new">Nowość</div>
                        `
                  : ''}
                  <img src="${product.image}" alt="${product.name}" class="product-image">
                  <h3 class="product-name">${product.name}</h3>
                </div>
              `;
              productsHTML += productHTML;
            }

            modalContent.insertAdjacentHTML('beforeend', productsHTML);
            
            totalProducts = parseInt(products[0].total);
            productsLoaded += products.length;
            currentPage++;

            if (productsLoaded >= totalProducts) {
              allProductsLoaded = true;
            }


            if (!allProductsLoaded) {
              const loadMoreDiv = document.createElement('div');
              loadMoreDiv.classList.add('loadMoreWrapper');
              const newLoadMoreButton = document.createElement('button');
              newLoadMoreButton.textContent = 'Zobacz więcej';
              newLoadMoreButton.classList.add('load-more-button', 'button');
              loadMoreDiv.appendChild(newLoadMoreButton);
              modalContainer.appendChild(loadMoreDiv);
              newLoadMoreButton.addEventListener('click', function () {
                loadMoreProducts(categoryId, currentPage, filters);
              });
            }
          }
        }
      });
    }
  })
}