
export function initFindSalon() {
    document.addEventListener('DOMContentLoaded', function () {
        const findSalonSearch = document.querySelector('.blockFindSalon');

        if (findSalonSearch) {
            findSalonSearch.querySelector('#searchform').addEventListener('submit', function () {
                function checkAndNavigate() {
                    const searchResults = document.querySelector('.searchbox-results');
                    if (searchResults) {
                        const firstLink = searchResults.querySelector('a[href]');
                        if (firstLink) {
                            const href = firstLink.href;
                            window.location.href = href;
                        }
                    }
                }
                setTimeout(checkAndNavigate, 1000);
            });
        }
    });
}
