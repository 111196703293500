export function initBlogCategorySlider() {
    document.addEventListener('DOMContentLoaded', function () {
        const categories = document.querySelectorAll('.blogPage__categoryWrapper');

        if (categories) {
            categories.forEach(category => {
                const splide = new window.splide(category, {
                    gap: '12px',
                    pagination: false,
                    arrows: false,
                    perPage: 1,
                    perMove: 1,
                    mediaQuery: 'min',
                    autoWidth: true,
                    breakpoints: {
                        992: {
                            destroy: true,
                        }
                    }
                });
                splide.mount();
            })
        }
    })
}