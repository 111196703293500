export function initBulletsSlider() {
    document.addEventListener('DOMContentLoaded', function () {
        const bulletSliders = document.querySelectorAll('.blockBullets');

        if (bulletSliders) {

            bulletSliders.forEach(blogSlider => {
                const sliderWrapper = blogSlider.querySelector('.blockBullets__slider');
                const desktopItems = sliderWrapper.getAttribute('data-items');

                const Slider = new window.splide(sliderWrapper, {
                    gap: '0px',
                    arrows: true,
                    perPage: desktopItems,
                    arrowPath: 'M16.9091 7.57564C17.1434 7.34132 17.5233 7.34132 17.7576 7.57564L25.7576 15.5756C25.8701 15.6882 25.9333 15.8408 25.9333 15.9999C25.9333 16.159 25.8701 16.3116 25.7576 16.4242L17.7576 24.4242C17.5233 24.6585 17.1434 24.6585 16.9091 24.4242C16.6747 24.1899 16.6747 23.81 16.9091 23.5756L23.8848 16.5999H6.66665C6.33528 16.5999 6.06665 16.3313 6.06665 15.9999C6.06665 15.6685 6.33528 15.3999 6.66665 15.3999H23.8848L16.9091 8.42417C16.6747 8.18985 16.6747 7.80995 16.9091 7.57564Z',
                    perMove: 1,
                    pagination: false,

                    type: 'loop',
                    interval: 4000,
                    speed: 800,
                    autoplay: "play",


                    breakpoints: {
                        1200: {
                            perPage: 4,
                            perMove: 1,
                            rewind: true,
                        },
                        991: {
                            perPage: 2,
                            perMove: 1,
                            rewind: true,
                            arrows: false,

                        },
                        767: {
                            perPage: 1,
                            perMove: 1,
                            rewind: true,
                        },
                    }
                });

                Slider.on('overflow', function (isOverflow) {
                    if (!isOverflow) {
                        Slider.go(0);

                        Slider.options = {
                            arrows: isOverflow,
                            pagination: isOverflow,
                            drag: isOverflow,
                            clones: isOverflow ? undefined : 0,
                        };
                    }
                });

                // const progressBar = Slider.root.querySelector('.progress-bar');

                // Slider.on('mounted move', () => {
                //     let end = Slider.Components.Controller.getEnd() + 1;
                //     let rate = Math.min((Slider.index + 1) / end, 1);

                //     progressBar.style.maxWidth = `${100 * rate}%`;
                // })

                Slider.mount();

            })
        }
    })
}