export function sliderCategory() {
  document.addEventListener('DOMContentLoaded', function () {
      const slider = document.querySelector('.blockSliderCategory__slider');

      const sliderInstance = new window.splide(slider, {
          gap: '12px',
          arrows: false,
          perPage: 3,
          pagination: false,
          drag: true,
          breakpoints: {
              1200: {
                  perPage: 2
              },
              800: {
                  perPage: 1
              },
          },
      });

      const progressBar = slider.parentNode.querySelector('.progress-bar');

      sliderInstance.on('mounted move', () => {
          let end = sliderInstance.Components.Controller.getEnd() + 1;
          let rate = Math.min((sliderInstance.index + 1) / end, 1);

          progressBar.style.maxWidth = `${100 * rate}%`;
      });

      const nextButton = document.querySelector('.blockSliderCategory__content .blockSliderCategory__next');

      if (nextButton) {
          nextButton.addEventListener('click', () => {
              sliderInstance.go('+1');
          });
      }

      sliderInstance.mount();
  });
}
