export function salonInfo() {
  document.addEventListener('DOMContentLoaded', function () {
    const sliders = document.querySelectorAll('.blockSalonInfo__gallerySlider');
    const imagesList = document.querySelectorAll('.ngg-simplelightbox');

    if (imagesList) {
      lightbox = new window.lightbox({
        elements: '.blockSalonInfo__gallery .ngg-simplelightbox',
        captionAttribute: 'data-title'
      });
    }

    sliders.forEach(function (slider) {
      const sliderInstance = new window.splide(slider, {
        arrows: true,
        perPage: 1,
        perMove: 1,
        pagination: false,
        drag: true,
        autoplay: true,
        interval: 3000,
      });

      sliderInstance.on('overflow', function (isOverflow) {
        if (!isOverflow) {
          sliderInstance.go(0);

          sliderInstance.options = {
            arrows: isOverflow,
            pagination: isOverflow,
            drag: isOverflow,
            clones: isOverflow ? undefined : 0,
          };
        }
      });

      sliderInstance.mount();
    });
  });
}
