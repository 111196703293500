export function initSalonGallery() {
    document.addEventListener('DOMContentLoaded', function () {
        const salonGallery = document.querySelector('.blockSalonGallery__gallery');
        const salonGalleryWrapper = document.querySelector('.blockSalonGallery__wrapper');

        if (salonGallery) {
                const Slider = new window.splide(salonGallery, {
                    gap: '12px',
                    type:"loop",
                    arrows: false,
                    perPage: 3,
                    perMove: 1,
                    autoWidth: true,
                    focus: 'center',
                    padding: '0px',
                    pagination: false,
                    breakpoints: {
                        992: {
                                type:"slider",
                            }
                        },
                });

                Slider.on('mounted', function () {
                    const track = salonGallery.querySelector('.splide__track');
                    track.classList.add('transform')
                });

                const ArrowPrev = salonGalleryWrapper.querySelector('.custom-prev');
                const ArrowNext = salonGalleryWrapper.querySelector('.custom-next');

                ArrowNext.addEventListener("click", nextFunction);
                ArrowPrev.addEventListener("click", prevFunction);

                function nextFunction() {
                    Slider.go(Slider.index + 1);
                    const track = salonGallery.querySelector('.splide__track');
                    track.classList.add('transformNext')
                }

                function prevFunction() {
                    Slider.go(Slider.index - 1);
                    const track = salonGallery.querySelector('.splide__track');
                    track.classList.remove('transformNext')
                }

                Slider.mount();
        }
    })
}