export const initNewsModal = () => {
    document.addEventListener('DOMContentLoaded', () => {

        const htmlEl = document.querySelector('html');
        const bodyEl = document.querySelector('body');

        const modalNews = document.querySelector('.modalNews');
        const buttonNews = document.querySelector('.buttonModalNews');
        const modalNewsContent = document.querySelector('.modalNews__content');
        const currentPostId = buttonNews.getAttribute('data-post-id');
        let closeNewsModalListener = null;
        let currentPageNews = 1;
        var totalNews = 0;
        var newsLoaded = 0;
        let allNewsLoaded = false;

        const modalBackdrop = document.querySelector('.modalBackdrop');

        //OBSŁUGA MODALA Z AKTUALNOŚCIAMI

        buttonNews.addEventListener('click', () => {
            modalNews.style.display = 'block';
            htmlEl.classList.add('overflow-hidden');
            bodyEl.classList.add('overflow-hidden');
            modalBackdrop.classList.add('active');
            currentPageNews = 1;
            totalNews = 0;
            newsLoaded = 0;
            allNewsLoaded = false;
            displayNews();

            setTimeout(function () {
                modalNews.classList.add('show');
                closeNewsModalListener = hideNewsModal;
                document.addEventListener('click', closeNewsModalListener);
            }, 300);
        });

        function hideNewsModal(event) {
            let target = event.target;
            let isCloseButton = false;

            while (target !== document.body) {
                if (target.classList.contains('modalClose')) {
                    isCloseButton = true;
                    break;
                }
                target = target.parentNode;
            }
            if (!modalNews.contains(event.target) || isCloseButton) {
                modalNews.classList.remove('show');
                modalBackdrop.classList.remove('active');
                htmlEl.classList.remove('overflow-hidden');
                bodyEl.classList.remove('overflow-hidden');
                modalNewsContent.innerHTML = '';
                document.removeEventListener('click', closeNewsModalListener);
                closeNewsModalListener = null;

                setTimeout(function () {
                    modalNews.style.display = 'none';
                }, 300);
            }
        }

        function displayNews() {

            const salonSlug = window.location.pathname.split('/').filter(slug => slug !== '')[1];
            fetch(`/wp-json/krishome/v1/news/?post_id=${currentPostId}&page=${currentPageNews}&salon_slug=${salonSlug}`)
                .then(response => response.json())
                .then(news => {
                    if (news.length > 0) {
                        totalNews = parseInt(news[0].total);
                        newsLoaded = news.length;
                        news.forEach(post => {
                            const postHTML = `
                    <a href="${post.link}">
                        <div class="modalNews__singleNews">
                            ${post.thumbnail ? `<div class="modalNews__singleNewsImage">${post.thumbnail}</div>` : ''}
                            <span class="modalNews__singleNewsDate">${post.date}
                            <h3>${post.title}</h3>
                            </span>
                            <span class="modalNews__singleNewsIcon">
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4093 5.07564C10.6436 4.84132 11.0235 4.84132 11.2578 5.07564L16.2578 10.0756C16.3703 10.1882 16.4336 10.3408 16.4336 10.4999C16.4336 10.659 16.3703 10.8116 16.2578 10.9242L11.2578 15.9242C11.0235 16.1585 10.6436 16.1585 10.4093 15.9242C10.175 15.6899 10.175 15.31 10.4093 15.0756L14.385 11.0999H4.16689C3.83552 11.0999 3.56689 10.8313 3.56689 10.4999C3.56689 10.1685 3.83552 9.8999 4.16689 9.8999H14.385L10.4093 5.92417C10.175 5.68985 10.175 5.30995 10.4093 5.07564Z"/>
                            </svg>
                            </span>
                        </div>
                    </a>
                    `;
                            modalNewsContent.innerHTML += postHTML;
                        });

                        if (news.length >= 4) {
                            const loadMoreDiv = document.createElement('div');
                            loadMoreDiv.classList.add('loadMoreWrapper');
                            const loadMoreButton = document.createElement('button');
                            loadMoreButton.textContent = 'Załaduj więcej';
                            loadMoreButton.classList.add('loadMoreButton', 'button');
                            loadMoreDiv.appendChild(loadMoreButton);
                            modalNewsContent.appendChild(loadMoreDiv);
                            loadMoreButton.addEventListener('click', function () {
                                loadMoreNews(currentPageNews, salonSlug, totalNews);
                            });
                        } else {
                            const loadMoreDiv = document.querySelector('.loadMoreWrapper');
                            if (loadMoreDiv) {
                                loadMoreDiv.remove();
                            }
                        }
                    } else {
                        modalNewsContent.innerHTML = '<p>Brak aktualności do wyświetlenia.</p>';
                        totalNews = 0;
                        newsLoaded = 0;
                    }
                })
                .catch(error => {
                    console.error('Wystąpił błąd podczas pobierania danych:', error);
                });
        }

        function loadMoreNews(currentPageNews, salonSlug, totalNews) {
            currentPageNews++;
            fetch(`/wp-json/krishome/v1/news/?page=${currentPageNews}&salon_slug=${salonSlug}`)

                .then(function (response) {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Błąd pobierania aktualności');
                    }
                })
                .then(function (news) {

                    appendNews(news, salonSlug, totalNews);
                    newsLoaded += news.length;

                })
                .catch(function (error) {
                    console.error(error);
                });
        }

        function appendNews(news, salonSlug, totalNews) {
            let newsesHTML = '';

            if (news.length > 0) {
                const loadMoreDiv = document.querySelector('.loadMoreWrapper');
                if (loadMoreDiv) {
                    loadMoreDiv.remove();
                }

                for (let i = 0; i < news.length; i++) {
                    const post = news[i];
                    const newsHTML = `
                    <a href="${post.link}">
                    <div class="modalNews__singleNews">
                        ${post.thumbnail ? `<div class="modalNews__singleNewsImage">${post.thumbnail}</div>` : ''}
                        <span class="modalNews__singleNewsDate">${post.date}
                        <h3>${post.title}</h3>
                        </span>
                        <span class="modalNews__singleNewsIcon">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4093 5.07564C10.6436 4.84132 11.0235 4.84132 11.2578 5.07564L16.2578 10.0756C16.3703 10.1882 16.4336 10.3408 16.4336 10.4999C16.4336 10.659 16.3703 10.8116 16.2578 10.9242L11.2578 15.9242C11.0235 16.1585 10.6436 16.1585 10.4093 15.9242C10.175 15.6899 10.175 15.31 10.4093 15.0756L14.385 11.0999H4.16689C3.83552 11.0999 3.56689 10.8313 3.56689 10.4999C3.56689 10.1685 3.83552 9.8999 4.16689 9.8999H14.385L10.4093 5.92417C10.175 5.68985 10.175 5.30995 10.4093 5.07564Z"/>
                        </svg>
                        </span>
                    </div>
                </a>
                `;
                    newsesHTML += newsHTML;
                }

                modalNewsContent.insertAdjacentHTML('beforeend', newsesHTML);
                newsLoaded += news.length;
                currentPageNews++;

                if (newsLoaded >= totalNews) {
                    allNewsLoaded = true;
                }

                if (!allNewsLoaded) {
                    const loadMoreDiv = document.createElement('div');
                    loadMoreDiv.classList.add('loadMoreWrapper');
                    const loadMoreButton = document.createElement('button');
                    loadMoreButton.textContent = 'Załaduj więcej';
                    loadMoreButton.classList.add('loadMoreButton', 'button');
                    loadMoreDiv.appendChild(loadMoreButton);
                    modalNewsContent.appendChild(loadMoreDiv);
                    loadMoreButton.addEventListener('click', function () {
                        loadMoreNews(currentPageNews, salonSlug, totalNews);
                    });
                }
            }
        }
    });
}