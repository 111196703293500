
export function linkSliderScript() {
    document.addEventListener('DOMContentLoaded', function () {

        const sliderBlock = document.querySelector('.blockLinkSlider');
        if (!sliderBlock) return;

        const splideWrapper = sliderBlock.querySelector('.blockLinkSlider__splide');
        const progressWrapper = sliderBlock.querySelector('.blockLinkSlider__progress');

        if (!splideWrapper || !progressWrapper) return;

        const splideInstance = new window.splide(splideWrapper, {
            perPage: 3,
            pagination: false,
            arrows: false,
            gap: '32px',
            breakpoints: {
                767: {
                    perPage: 1,
                },
                991: {
                    perPage: 2,
                }
            }
        });

        const progress = new window.splide(progressWrapper, {
            arrows: false,
            pagination: false,
        });

        const progressBar = progress.root.querySelector('.progress-bar');

        splideInstance.on('mounted move', () => {
            let end = splideInstance.Components.Controller.getEnd() + 1;
            let rate = Math.min((splideInstance.index + 1) / end, 1);

            progressBar.style.maxWidth = `${100 * rate}%`;
        })

        const arrowPrev = sliderBlock.querySelector('.custom-prev');
        const arrowNext = sliderBlock.querySelector('.custom-next');

        if (!arrowNext || !arrowPrev) return;

        arrowNext.addEventListener("click", () => {
            splideInstance.go(splideInstance.index + 1);
        });

        arrowPrev.addEventListener("click", () => {
            splideInstance.go(splideInstance.index - 1);
        });

        splideInstance.sync(progress);
        splideInstance.mount();
        progress.mount();
    });
}