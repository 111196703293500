export function blockEmployeeScript() {
    document.addEventListener('DOMContentLoaded', function () {
        const employeeItem = document.querySelectorAll('.employeeCard');
        const employeeSelect = document.querySelector('.blockEmployee__select');

        if (employeeSelect) {
            showEmployee();
            employeeSelect.addEventListener("change", showEmployee);

            function showEmployee() {
                const selectedValue = employeeSelect.value;

                employeeItem.forEach((elem) => {
                    const dataAttrValue = elem.getAttribute("data-provinces");
                    const dataAttrValueSplit = dataAttrValue.split(", ");

                    const shouldShow = selectedValue === 'all' || dataAttrValueSplit.includes(selectedValue);

                    elem.classList.toggle('hide', !shouldShow);
                    elem.classList.toggle('show', shouldShow);
                });
            }
        }
    })
}