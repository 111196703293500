export const initJobOffersModal = () => {
    document.addEventListener('DOMContentLoaded', () => {

        const htmlEl = document.querySelector('html');
        const bodyEl = document.querySelector('body');
        const modalJobOffers = document.querySelector('.modalJobOffers');
        const buttonJobOffers = document.querySelector('.buttonModalJobOffers');
        const modalJobOffersContent = document.querySelector('.modalJobOffers__content');
        let closeJobOffersModalListener = null;
        let currentPageJobOffers = 1;
        var totalJobOffers = 0;
        var jobOffersLoaded = 0;
        let allJobOffersLoaded = false;
        const modalBackdrop = document.querySelector('.modalBackdrop');
        const salonAdderessSpan = document.querySelector('.blockSalonNews__singleJobOffersAddress');
        const salonAdderess = salonAdderessSpan ? salonAdderessSpan.textContent : '';
        const modalSingleJobOffersContent = document.querySelector('.modalSingleJobOffers__content');
        const modalSingleJobOffers = document.querySelector('.modalSingleJobOffers');
        let closeSingleJobOffersModalListener = null;

        if (buttonJobOffers) {
            buttonJobOffers.addEventListener('click', () => {
                modalJobOffers.style.display = 'block';
                htmlEl.classList.add('overflow-hidden');
                bodyEl.classList.add('overflow-hidden');
                modalBackdrop.classList.add('active');
                currentPageJobOffers = 1;
                totalJobOffers = 0;
                jobOffersLoaded = 0;
                allJobOffersLoaded = false;
                displayJobOffers();

                setTimeout(function () {
                    modalJobOffers.classList.add('show');
                    closeJobOffersModalListener = hideJobOffersModal;
                    document.addEventListener('click', closeJobOffersModalListener);
                }, 300);
            });
        }

        function hideJobOffersModal(event) {
            let target = event.target;
            let isCloseButton = false;

            while (target !== document.body) {
                if (target.classList.contains('modalClose')) {
                    isCloseButton = true;
                    break;
                }
                target = target.parentNode;
            }
            if (!modalJobOffers.contains(event.target) && !modalSingleJobOffers.contains(event.target) || isCloseButton) {
                modalJobOffers.classList.remove('show');
                modalBackdrop.classList.remove('active');
                htmlEl.classList.remove('overflow-hidden');
                bodyEl.classList.remove('overflow-hidden');
                modalJobOffersContent.innerHTML = '';
                document.removeEventListener('click', closeJobOffersModalListener);
                closeJobOffersModalListener = null;

                setTimeout(function () {
                    modalJobOffers.style.display = 'none';
                    modalSingleJobOffers.style.display = 'none';
                }, 300);
            }
        }

        let jobOffersContainer = document.querySelector('.modalJobOffers__content');

        function handleJobOfferClick(event) {
            const jobOfferElement = event.target.closest('.jobOffer');
            if (jobOfferElement) {
                const jobOfferId = jobOfferElement.dataset.jobofferid;
                fetchSingleOffers(jobOfferId);
            }
        }

        function displayJobOffers() {

            const salonSlug = window.location.pathname.split('/').filter(slug => slug !== '')[1];
            fetch(`/wp-json/krishome/v1/job-offers/?page=${currentPageJobOffers}&salon_slug=${salonSlug}`)
                .then(response => response.json())
                .then(jobOffers => {

                    if (jobOffers.length > 0) {
                        totalJobOffers = parseInt(jobOffers[0].total);
                        jobOffersLoaded = jobOffers.length;
                        jobOffers.forEach(jobOffer => {
                            const jobOffersHTML = `
                        <div class="modalJobOffers__singleJobOffers jobOffer" data-jobofferid="${jobOffer.id}">
                            <div class="modalJobOffers__singleJobOffersWrapper">
                            <span>${salonAdderess}</span>
                            <h3>${jobOffer.title}</h3>
                            </div>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4093 5.07564C10.6436 4.84132 11.0235 4.84132 11.2578 5.07564L16.2578 10.0756C16.3703 10.1882 16.4336 10.3408 16.4336 10.4999C16.4336 10.659 16.3703 10.8116 16.2578 10.9242L11.2578 15.9242C11.0235 16.1585 10.6436 16.1585 10.4093 15.9242C10.175 15.6899 10.175 15.31 10.4093 15.0756L14.385 11.0999H4.16689C3.83552 11.0999 3.56689 10.8313 3.56689 10.4999C3.56689 10.1685 3.83552 9.8999 4.16689 9.8999H14.385L10.4093 5.92417C10.175 5.68985 10.175 5.30995 10.4093 5.07564Z"/>
                            </svg>
                        </div>
                    `;

                            modalJobOffersContent.innerHTML += jobOffersHTML;
                        });

                        jobOffersContainer.removeEventListener('click', handleJobOfferClick);
                        jobOffersContainer.addEventListener('click', handleJobOfferClick);

                        if (jobOffers.length >= 6) {
                            const loadMoreDiv = document.createElement('div');
                            loadMoreDiv.classList.add('loadMoreWrapper');
                            const loadMoreButton = document.createElement('button');
                            loadMoreButton.textContent = 'Załaduj więcej';
                            loadMoreButton.classList.add('loadMoreButton', 'button');
                            loadMoreDiv.appendChild(loadMoreButton);
                            modalJobOffersContent.appendChild(loadMoreDiv);
                            loadMoreButton.addEventListener('click', function () {
                                loadMoreJobOffers(currentPageJobOffers, salonSlug, totalJobOffers);
                            });
                        } else {
                            const loadMoreDiv = document.querySelector('.loadMoreWrapper');
                            if (loadMoreDiv) {
                                loadMoreDiv.remove();
                            }
                        }
                    } else {
                        modalJobOffersContent.innerHTML = '<p>Brak aktualnych ofert pracy do wyświetlenia.</p>';
                        totalJobOffers = 0;
                        jobOffersLoaded = 0;
                    }
                })
                .catch(error => {
                    console.error('Wystąpił błąd podczas pobierania danych:', error);
                });
        }

        function loadMoreJobOffers(currentPageJobOffers, salonSlug, totalJobOffers) {
            currentPageJobOffers++;
            fetch(`/wp-json/krishome/v1/job-offers/?page=${currentPageJobOffers}&salon_slug=${salonSlug}`)

                .then(function (response) {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Błąd pobierania ofert pracy');
                    }
                })
                .then(function (jobOffers) {

                    appendJobOffers(jobOffers, salonSlug, totalJobOffers);
                    jobOffersLoaded += jobOffers.length;

                })
                .catch(function (error) {
                    console.error(error);
                });
        }

        function appendJobOffers(jobOffers, salonSlug, totalJobOffers) {
            let jobOffersHTML = '';

            if (jobOffers.length > 0) {
                const loadMoreDiv = document.querySelector('.loadMoreWrapper');
                if (loadMoreDiv) {
                    loadMoreDiv.remove();
                }

                for (let i = 0; i < jobOffers.length; i++) {
                    const post = jobOffers[i];
                    const jobOfferHTML = `
                        <div class="modalJobOffers__singleJobOffers jobOffer" data-jobofferid="${post.id}">
                            <div class="modalJobOffers__singleJobOffersWrapper">
                            <span>${salonAdderess}</span>
                            <h3>${post.title}</h3>
                            </div>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4093 5.07564C10.6436 4.84132 11.0235 4.84132 11.2578 5.07564L16.2578 10.0756C16.3703 10.1882 16.4336 10.3408 16.4336 10.4999C16.4336 10.659 16.3703 10.8116 16.2578 10.9242L11.2578 15.9242C11.0235 16.1585 10.6436 16.1585 10.4093 15.9242C10.175 15.6899 10.175 15.31 10.4093 15.0756L14.385 11.0999H4.16689C3.83552 11.0999 3.56689 10.8313 3.56689 10.4999C3.56689 10.1685 3.83552 9.8999 4.16689 9.8999H14.385L10.4093 5.92417C10.175 5.68985 10.175 5.30995 10.4093 5.07564Z"/>
                            </svg>
                        </div>
                `;
                    jobOffersHTML += jobOfferHTML;
                }

                modalJobOffersContent.insertAdjacentHTML('beforeend', jobOffersHTML);
                jobOffersLoaded += jobOffers.length;
                currentPageJobOffers++;

                if (jobOffersLoaded >= totalJobOffers) {
                    allJobOffersLoaded = true;
                }

                if (!allJobOffersLoaded) {
                    const loadMoreDiv = document.createElement('div');
                    loadMoreDiv.classList.add('loadMoreWrapper');
                    const loadMoreButton = document.createElement('button');
                    loadMoreButton.textContent = 'Załaduj więcej';
                    loadMoreButton.classList.add('loadMoreButton', 'button');
                    loadMoreDiv.appendChild(loadMoreButton);
                    modalJobOffersContent.appendChild(loadMoreDiv);
                    loadMoreButton.addEventListener('click', function () {
                        loadMoreJobOffers(currentPageJobOffers, salonSlug, totalJobOffers);
                    });
                }
            }
        }

        function fetchSingleOffers(jobOfferstId) {

            fetch(`/wp-json/krishome/v1/single-job-offers/?id=${jobOfferstId}`)
                .then(response => response.json())
                .then(singleJobOffers => {
                    modalSingleJobOffersContent.innerHTML = '';
                    if (singleJobOffers.length > 0) {
                        singleJobOffers.forEach(singleJobOffer => {

                            const singleJobOffersHTML = `
                            <div class="modalSingleJobOffers__single">
                                <h6>${singleJobOffer.title}</h6>
                                <span>${salonAdderess}</span>
                                <div class="modalSingleJobOffers__singleDescription">
                                ${singleJobOffer.description}
                                </div>
                            </div>
                        `;

                            modalSingleJobOffersContent.innerHTML += singleJobOffersHTML;
                            showModalSingleOffers();
                        });
                    }
                })
                .catch(error => {
                    console.error('Wystąpił błąd podczas pobierania danych:', error);
                });
        }

        function showModalSingleOffers() {
            modalSingleJobOffers.style.display = 'block';
            htmlEl.classList.add('overflow-hidden');
            bodyEl.classList.add('overflow-hidden');
            modalBackdrop.classList.add('active');

            setTimeout(function () {
                modalSingleJobOffers.classList.add('show');
                closeSingleJobOffersModalListener = hideSingleJobOffersModal;
                document.addEventListener('click', closeSingleJobOffersModalListener);
            }, 300);
        }

        function hideSingleJobOffersModal(event) {
            let target = event.target;
            let isCloseButton = false;

            while (target !== document.body) {
                if (target.classList.contains('modalClose')) {
                    isCloseButton = true;
                    break;
                }
                target = target.parentNode;
            }
            if (!modalSingleJobOffers.contains(event.target) || isCloseButton) {
                modalSingleJobOffers.classList.remove('show');
                modalBackdrop.classList.remove('active');
                htmlEl.classList.remove('overflow-hidden');
                bodyEl.classList.remove('overflow-hidden');
                modalSingleJobOffersContent.innerHTML = '';
                document.removeEventListener('click', closeSingleJobOffersModalListener);
                closeSingleJobOffersModalListener = null;

                setTimeout(function () {
                    modalSingleJobOffers.style.display = 'none';
                }, 300);
            }
        }

    });
}