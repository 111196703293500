import axios from "axios";

export const initGalleryLoop = () => {
    const blocks = document.querySelectorAll('.blockGalleryLoop');
    let lightbox = null;

    if (blocks) {
        blocks.forEach(block => {
            const loop = block.querySelector('.blockGalleryLoop__loop');
            const tagsWrapper = block.querySelector('.blockGalleryLoop__filters__galleries--tags');
            const filters = block.querySelectorAll('.blockGalleryLoop__filters__galleries__item');
            const loadMore = block.querySelector('.blockGalleryLoop__nav button');

            if (filters) {
                filters.forEach(filter => {
                    filter.addEventListener('click', () => {
                        if (filter.classList.contains('active')) return;

                        let id = filter.getAttribute('data-id');
                        resetFilters(filters);
                        let tags = block.querySelectorAll('.blockGalleryLoop__filters__galleries__tag');
                        if(tags) resetTags(tags);

                        loop.setAttribute('data-id', parseInt(id));
                        loop.setAttribute('data-page', '1');
                        loop.setAttribute('data-tag', '');
                        filter.classList.add('active');
                        loadMore.setAttribute('disabled', 'disabled');

                        loop.dispatchEvent(
                            new CustomEvent("reload-gallery", {
                                detail: {
                                    id: loop.getAttribute('data-id'),
                                    limit: loop.getAttribute('data-limit'),
                                    page: loop.getAttribute('data-page'),
                                    type: loop.getAttribute('data-type'),
                                    cat: loop.getAttribute('data-cat'),
                                    tags: loop.getAttribute('data-tag'),
                                    allowed_tags: loop.getAttribute('data-tag-limit')
                                }
                            })
                        );
                    });
                })
            }

            if (tagsWrapper) {
                document.addEventListener('click', function(e) {
                    if(e.target && e.target.matches && e.target.matches('.blockGalleryLoop__filters__galleries__tag')) {
                        const tag = e.target;
                        let tags = block.querySelectorAll('.blockGalleryLoop__filters__galleries__tag');

                        if (tag.classList.contains('active')) {
                            loop.setAttribute('data-tag', '');
                            resetTags(tags);
                        } else {
                            resetTags(tags);
                            tag.classList.add('active');
                            let dataTag = tag.getAttribute('data-tag');

                            loop.setAttribute('data-tag', dataTag);
                        }
                        loop.setAttribute('data-page', '1');
                        loadMore.setAttribute('disabled', 'disabled');

                        loop.dispatchEvent(
                            new CustomEvent("reload-gallery", {
                                detail: {
                                    id: loop.getAttribute('data-id'),
                                    limit: loop.getAttribute('data-limit'),
                                    page: loop.getAttribute('data-page'),
                                    type: loop.getAttribute('data-type'),
                                    cat: loop.getAttribute('data-cat'),
                                    tags: loop.getAttribute('data-tag'),
                                    allowed_tags: loop.getAttribute('data-tag-limit')
                                }
                            })
                        );
                    }
                });
            }

            loop.addEventListener('reload-gallery', e => {
                let id = e.detail.id;
                let limit = e.detail.limit;
                let page = e.detail.page;
                let type = e.detail.type;
                let cat = e.detail.cat;
                let tags = e.detail.tags;
                let allowedTags = e.detail.allowed_tags;

                axios.get(`${ajax.url}?action=get_gallery`, {
                    params: {
                        id: id,
                        limit: limit,
                        page: parseInt(page),
                        type: type,
                        cat: cat,
                        tags: tags,
                        allowed_tags: allowedTags
                    }
                }).then(response => {
                    if (parseInt(page) > 1) loop.innerHTML += response.data.data
                    else loop.innerHTML = response.data.data;

                    if(tagsWrapper) {
                        tagsWrapper.innerHTML = response.data.tags;
                    }

                    if (response.data.pagination) {
                        loadMore.style.display = "flex";
                        loop.setAttribute('data-page', parseInt(page) + 1);
                    } else loadMore.style.display = "none";

                    loadMore.removeAttribute('disabled');

                    lightbox.destroy();
                    lightbox = new window.lightbox({
                        elements: '.blockGalleryLoop__loop .ngg-simplelightbox',
                        captionAttribute: 'data-title'
                    });
                });
            })

            if (loadMore) {
                loadMore.addEventListener('click', e => {
                    e.preventDefault();
                    loadMore.setAttribute('disabled', 'disabled');
                    loop.dispatchEvent(
                        new CustomEvent("reload-gallery", {
                            detail: {
                                id: loop.getAttribute('data-id'),
                                limit: loop.getAttribute('data-limit'),
                                page: loop.getAttribute('data-page'),
                                type: loop.getAttribute('data-type'),
                                cat: loop.getAttribute('data-cat'),
                                tags: loop.getAttribute('data-tag'),
                                allowed_tags: loop.getAttribute('data-tag-limit')
                            }
                        })
                    );
                });
            }
        });

        const resetFilters = (filters) => {
            filters.forEach(filter => {
                filter.classList.remove('active');
            })
        }
        const resetTags = (tags) => {
            tags.forEach(tag => {
                tag.classList.remove('active');
            })
        }

        window.addEventListener('DOMContentLoaded', () => {
            lightbox = new window.lightbox({
                elements: '.blockGalleryLoop__loop .ngg-simplelightbox',
                captionAttribute: 'data-title'
            });
        })
    }
}