import axios from "axios";

export const initSearchModal = () => {
    const searchModal = document.querySelector('.searchModal');
    const htmlTag = document.querySelector('html');
    const bodyTag = document.querySelector('body');
    const openModal = document.querySelector('.header__search-btn');

    if (openModal) {
        openModal.addEventListener('click', () => {
            searchModal.classList.toggle('active');
            htmlTag.classList.toggle('overflow-hidden');
            bodyTag.classList.toggle('overflow-hidden');
        })
    }

    document.addEventListener('click', function (event) {
        const searchModal = document.querySelector('.searchModal');
        const wrapper = document.querySelector('.searchModal__wrapper');

        if (!wrapper.contains(event.target) && searchModal.classList.contains('active') && !openModal.contains(event.target)) {
            searchModal.classList.remove('active');
            htmlTag.classList.toggle('overflow-hidden');
            bodyTag.classList.toggle('overflow-hidden');
        }
    });

    if (searchModal) {

        const modalForm = searchModal.querySelector('form');
        const searchInput = searchModal.querySelector('input');
        const searchResults = searchModal.querySelector('.searchModal__results');
        const searchLoading = searchModal.querySelector('.searchModal__loading');

        const clearSearch = searchModal.querySelector('.searchModal__clear');
        clearSearch.addEventListener('click', e => {
            e.preventDefault();
            searchInput.value = '';
            searchResults.innerHTML = "";
            // searchModal.classList.remove('active');
            htmlTag.classList.remove('overflow-hidden');
            bodyTag.classList.remove('overflow-hidden');
        });
        modalForm.addEventListener('submit', e => {
            e.preventDefault();
            if (searchInput.value.length > 0) window.location = `${window.location.origin}/?s=${searchInput.value}&post_type=post`;
        });

        let timeoutId;
        searchInput.addEventListener('input', () => {
            searchLoading.classList.remove('active');
            clearTimeout(timeoutId);
            if (searchInput.value.length > 3) {
                timeoutId = setTimeout(() => {
                    searchResults.innerHTML = "";
                    searchLoading.classList.add('active');
                    axios.post(`/wp-json/krishome/v1/search`, {
                        search: searchInput.value
                    }).then(response => {
                        searchResults.innerHTML = response.data.html;
                        searchLoading.classList.remove('active');
                    });
                }, 500);
            } else {
                searchResults.innerHTML = "";
            }
        });
    }
}