export function productSlider() {
  document.addEventListener('DOMContentLoaded', function () {
    const slider = document.querySelector('.blockProductSlider__slider');
    const perPageDesktop = slider.getAttribute('data-desktop') || 2;
    const perPageTablet = slider.getAttribute('data-tablet') || 1;

    const sliderInstance = new window.splide(slider, {
      arrows: false,
      perPage: parseInt(perPageDesktop),
      perMove: 1,
      pagination: false,
      drag: true,
      gap: 16,
      breakpoints: {
        1200: {
          perPage: parseInt(perPageTablet)
        },
        800: {
          perPage: 1
        },
      },
    });

    const progressBar = slider.parentNode.querySelector('.progress-bar');
    const prevButton = document.querySelector('.blockProductSlider__arrows .blockProductSlider__prev');
    const nextButton = document.querySelector('.blockProductSlider__arrows .blockProductSlider__next');

    function toggleArrows() {
      const totalSlides = sliderInstance.length;
      const currentPerPage = sliderInstance.options.perPage;

      if (totalSlides <= currentPerPage) {
        if (prevButton) prevButton.style.display = 'none';
        if (nextButton) nextButton.style.display = 'none';
      } else {
        if (prevButton) prevButton.style.display = '';
        if (nextButton) nextButton.style.display = '';
      }
    }

    if (prevButton && nextButton) {
      prevButton.addEventListener('click', () => {
        sliderInstance.go('-1');
      });

      nextButton.addEventListener('click', () => {
        sliderInstance.go('+1');
      });
    }

    if (prevButton && nextButton) {
      prevButton.addEventListener('click', () => {
        sliderInstance.go('-1');
      });

      nextButton.addEventListener('click', () => {
        sliderInstance.go('+1');
      });
    }

    sliderInstance.on('mounted move', () => {
      let end = sliderInstance.Components.Controller.getEnd() + 1;
      let rate = Math.min((sliderInstance.index + 1) / end, 1);

      progressBar.style.maxWidth = `${100 * rate}%`;
    });

    sliderInstance.mount();
    toggleArrows();

  })
}