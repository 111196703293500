export function salonProductSlider() {
  document.addEventListener('DOMContentLoaded', function () {
    const slider = document.querySelector('.blockSalonProducts__slider');

    const sliderInstance = new window.splide(slider, {
      arrows: false,
      perPage: 2,
      perMove: 1,
      pagination: false,
      drag: true,
      gap: 16,
      breakpoints: {
        1200: {
          perPage: 2
        },
        800: {
          perPage: 1
        },
      },
    });

    const progressBar = slider.parentNode.querySelector('.progress-bar');
    const prevButton = document.querySelector('.blockSalonProducts__arrows .blockSalonProducts__prev');
    const nextButton = document.querySelector('.blockSalonProducts__arrows .blockSalonProducts__next');

    if (prevButton && nextButton) {
      prevButton.addEventListener('click', () => {
        sliderInstance.go('-1');
      });

      nextButton.addEventListener('click', () => {
        sliderInstance.go('+1');
      });
    }

    sliderInstance.on('mounted move', () => {
      let end = sliderInstance.Components.Controller.getEnd() + 1;
      let rate = Math.min((sliderInstance.index + 1) / end, 1);

      progressBar.style.maxWidth = `${100 * rate}%`;
    });

    sliderInstance.mount();


  })
}
