export function initRealisationsGallery() {
    document.addEventListener('DOMContentLoaded', function () {
        const realisationsGallery = document.querySelector('.blockRealisationsGallery__loop');
        const imagesList = realisationsGallery.querySelectorAll('.ngg-simplelightbox');

        if (imagesList) {
            lightbox = new window.lightbox({
              elements: '.blockRealisationsGallery__loop .ngg-simplelightbox',
              captionAttribute: 'data-title'
            });
          }

        if (realisationsGallery) {
                const Slider = new window.splide(realisationsGallery, {
                    gap: '24px',
                    arrows: false,
                    perPage: 1,
                    perMove: 1,
                    autoWidth: true,
                    pagination: false,
                });

                Slider.on('overflow', function (isOverflow) {
                    if (!isOverflow) {
                        Slider.go(0);

                        Slider.options = {
                            arrows: isOverflow,
                            pagination: isOverflow,
                            drag: isOverflow,
                            clones: isOverflow ? undefined : 0,
                        };
                    }
                });

                const progressBar = Slider.root.querySelector('.progress-bar');

                Slider.on('mounted move', () => {
                    let end = Slider.Components.Controller.getEnd() + 1;
                    let rate = Math.min((Slider.index + 1) / end, 1);

                    progressBar.style.maxWidth = `${100 * rate}%`;
                })

                const ArrowPrev = realisationsGallery.querySelector('.custom-prev');
                const ArrowNext = realisationsGallery.querySelector('.custom-next');

                ArrowNext.addEventListener("click", nextFunction);
                ArrowPrev.addEventListener("click", prevFunction);

                function nextFunction() {
                    Slider.go(Slider.index + 1);
                }

                function prevFunction() {
                    Slider.go(Slider.index - 1);
                }

                Slider.mount();
        }
    })
}