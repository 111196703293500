export function initCertificatesSlider() {
    document.addEventListener('DOMContentLoaded', function () {
        const sliderWrapper = document.querySelectorAll('.blockSalonCertificates__img');

        if (sliderWrapper) {
            sliderWrapper.forEach(certyficate => {
                const Slider = new window.splide(certyficate, {
                    gap: '32px',
                    arrows: false,
                    perPage: 3,
                    type: 'slide',
                    perMove: 1,
                    drag: true,
                    pagination: false,
                    breakpoints: {
                        992: {
                            type: 'slide',
                            focus: 0,
                            autoWidth: true,

                        },
                    }
                });
                Slider.mount();
            })
        }
    })
}