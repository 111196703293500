export function initSteps() {
    document.addEventListener('DOMContentLoaded', function () {
        const groups = document.querySelectorAll('.blockSteps__group');
        const radios = document.querySelectorAll('input[type="radio"]');

        // Hide all groups except the first one
        groups.forEach((group, index) => {
            if (index !== 0) {
                group.classList.add('hidden');
            }
        });

        // Add event listeners to radios
        radios.forEach(radio => {
            radio.addEventListener('change', function () {
                const selectedGroupId = radio.value;
                const inputNameGroupId = radio.name;

                // Hide all groups except those with selected radios
                groups.forEach(group => {
                    const groupRadios = group.querySelectorAll('input[type="radio"]');
                    let hasSelectedRadio = false;
                    groupRadios.forEach(groupRadio => {
                        if (groupRadio.checked) {
                            hasSelectedRadio = true;
                        }
                    });

                    if (!hasSelectedRadio) {
                        group.classList.add('hidden');
                        groupRadios.forEach(groupRadio => {
                            groupRadio.checked = false;
                        });

                    } else {
                        group.classList.remove('hidden');
                    }
                });

                // Show the selected group
                const selectedGroup = document.querySelector(`.blockSteps__group[group-id="${selectedGroupId}"]`);
                if (selectedGroup) {
                    selectedGroup.classList.remove('hidden');



                }

                // Also show the group corresponding to the input name
                const nameGroup = document.querySelector(`.blockSteps__group[group-id="${inputNameGroupId}"]`);
                if (nameGroup) {
                    nameGroup.classList.remove('hidden');

                }

                // Hide groups associated with unchecked radio buttons
                radios.forEach(radio => {
                    if (!radio.checked) {
                        const groupToHide = document.querySelector(`.blockSteps__group[group-id="${radio.value}"]`);
                        if (groupToHide) {
                            groupToHide.classList.add('hidden');

                            const groupRadios = groupToHide.querySelectorAll('input[type="radio"]');
                            groupRadios.forEach(groupRadio => {
                                groupRadio.checked = false;
                            });
                        }
                    }
                });

                const scrollToGroup = document.querySelector(`.blockSteps__group[group-id="${selectedGroupId}"]`);
                if (scrollToGroup) {
                    const groupPosition = scrollToGroup.getBoundingClientRect().top + window.pageYOffset - 100;
                    window.scrollTo({
                        top: groupPosition,
                        behavior: 'smooth'
                    });
                }
            });
        });
    });
}
