export function heroHomeScript() {
    document.addEventListener('DOMContentLoaded', function () {
        let mainHomeHero = new window.splide('.blockHeroSlider__sliderMain', {
            arrows: false,
            pagination: false,
            type: 'loop',

            interval: 4000,
            speed: 800,
            autoplay: "play",

            perPage: 1,
            grab: false,
        });

        let progressBarHero = document.querySelector('.blockHeroSliderProgress__bar');

        mainHomeHero.on('mounted move', function () {
            let end = mainHomeHero.Components.Controller.getEnd() + 1;
            let rate = Math.min((mainHomeHero.index + 1) / end, 1);
            progressBarHero.style.width = String(100 * rate) + '%';
        });

        let thumbnailsHome = new window.splide('.blockHeroSlider__sliderNav', {
            arrows: false,
            pagination: false,
            type: 'slide',
            perPage: 4,
            gap: 4,
            isNavigation: true,
            rewind: false,
            grab: false,
            breakpoints: {
                991: {
                    perPage: 1,
                }
            }
        });

        mainHomeHero.sync(thumbnailsHome);
        mainHomeHero.mount();
        thumbnailsHome.mount();

    })
}