export function blockEmployeeScript() {
    document.addEventListener('DOMContentLoaded', function () {
        const sections = document.querySelectorAll('.blockEmployee');

        if (!sections.length) return; // Exit if no .blockEmployee sections exist

        sections.forEach((section) => {
            const employeeItems = section.querySelectorAll('.employeeCard');
            const employeeSelect = section.querySelector('.blockEmployee__select');

            if (employeeSelect) {
                showEmployee();
                employeeSelect.addEventListener("change", showEmployee);

                function showEmployee() {
                    const selectedValue = employeeSelect.value;

                    employeeItems.forEach((elem) => {
                        const dataAttrValue = elem.getAttribute("data-provinces");
                        const dataAttrValueSplit = dataAttrValue ? dataAttrValue.split(", ") : [];

                        const shouldShow = selectedValue === 'all' || dataAttrValueSplit.includes(selectedValue);

                        elem.classList.toggle('hide', !shouldShow);
                        elem.classList.toggle('show', shouldShow);
                    });
                }
            }
        });
    });
}
