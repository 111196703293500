export function heroScript() {
    document.addEventListener('DOMContentLoaded', function () {
        const heroScroll = document.querySelectorAll('.blockHeroCard__scroll');

        if (heroScroll) {
            heroScroll.forEach(item => {
                item.addEventListener('click', () => {
                    window.scrollTo(0, 750);
                })
            });
        }
    })
}