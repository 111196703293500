import YTPlayer from 'yt-player';

export function shortVideoScript() {

    document.addEventListener('DOMContentLoaded', () => {
        const videoShorts = document.querySelectorAll('.blockVideo');

        if (videoShorts.length > 0) {
            videoShorts.forEach((videoShort) => {
                const videoId = videoShort.querySelector('.blockVideo__short').dataset.videoId;
                const startSeconds = parseInt(videoShort.querySelector('.blockVideo__short').getAttribute('data-start'));
                const endSeconds = parseInt(videoShort.querySelector('.blockVideo__short').getAttribute('data-stop'));
                const playerElement = videoShort.querySelector('.blockVideo__short');

                const player = new YTPlayer(playerElement, {
                    width: 640,
                    height: 360,
                    autoplay: true,
                    loop: true,
                    controls: false,
                    annotations: false,
                    modestBranding: true,
                });

                player.mute();

                if (startSeconds && endSeconds) {
                    player.load(videoId, true, startSeconds);
                    player.on('timeupdate', (e) => {
                        if (e >= endSeconds) {
                            player.seek(startSeconds);
                        }
                    });
                } else {
                    player.load(videoId, true);
                }

                const dialogEl = videoShort.querySelector('dialog');
                const closeDialog = videoShort.querySelector('.dialog-close');
                const openDialog = videoShort.querySelector('.blockVideo__play');
                const dialogBackground = videoShort.querySelector('.blockVideo__dialog_back');

                openDialog.addEventListener('click', () => {
                    dialogEl.showModal();
                });

                closeDialog.addEventListener('click', () => {
                    dialogEl.close();
                });

                dialogBackground.addEventListener('click', () => {
                    dialogEl.close();
                });
            });
        }

    });
};

