
export const initSingleJobOffersModal = () => {
    document.addEventListener('DOMContentLoaded', () => {  

        const wrapper = document.querySelector('.blockSalonNews__wrapperJobOffers');
        const jobOffersElements = wrapper.querySelectorAll('.jobOffer');
        const modalSingleJobOffers = document.querySelector('.modalSingleJobOffers');
        const modalSingleJobOffersContent = document.querySelector('.modalSingleJobOffers__content');
        const salonAdderessSpan = document.querySelector('.blockSalonNews__singleJobOffersAddress');
        const salonAdderess = salonAdderessSpan.textContent;
        const modalBackdrop = document.querySelector('.modalBackdrop');
        let closeSingleJobOffersModalListener = null;
        const back = document.querySelector('.modalSingleJobOffers__backIcon');
        const modalJobOffers = document.querySelector('.modalJobOffers');
        const htmlEl = document.querySelector('html');
        const bodyEl = document.querySelector('body');

            jobOffersElements.forEach(function(jobOffersElement) {
                    jobOffersElement.addEventListener('click', function() {
                        const jobOfferstId = jobOffersElement.dataset.jobofferid;
                        fetchSingleOffers(jobOfferstId);
                    });
                });

        function fetchSingleOffers(jobOfferstId) {

            fetch(`/wp-json/krishome/v1/single-job-offers/?id=${jobOfferstId}`)  
            .then(response => response.json())
            .then(singleJobOffers => {
                if (singleJobOffers.length > 0) {
                    singleJobOffers.forEach(singleJobOffer => {
                        
                        const singleJobOffersHTML = `
                            <div class="modalSingleJobOffers__single">
                                <h6>${singleJobOffer.title}</h6>
                                <span>${salonAdderess}</span>
                                <div class="modalSingleJobOffers__singleDescription">
                                ${singleJobOffer.description}
                                </div>
                            </div>
                        `;
                        
                        modalSingleJobOffersContent.innerHTML += singleJobOffersHTML;
                        showModalSingleOffers();
                    });
                } 
            })
            .catch(error => {
                console.error('Wystąpił błąd podczas pobierania danych:', error);
            });
        }

        function showModalSingleOffers() {
            modalSingleJobOffers.style.display = 'block';
            htmlEl.classList.add('overflow-hidden');
            bodyEl.classList.add('overflow-hidden');
            modalBackdrop.classList.add('active');

            setTimeout(function() {
                modalSingleJobOffers.classList.add('show');
                closeSingleJobOffersModalListener = hideSingleJobOffersModal;
                document.addEventListener('click', closeSingleJobOffersModalListener);
            }, 300);
        }

        function hideSingleJobOffersModal(event) {
            let target = event.target;
            let isCloseButton = false;

            while (target !== document.body) {
                if (target.classList.contains('modalClose')) {
                    isCloseButton = true;
                    break;
                }
                target = target.parentNode;
            }
            if (!modalSingleJobOffers.contains(event.target) || isCloseButton) {
                modalSingleJobOffers.classList.remove('show');
                modalBackdrop.classList.remove('active');
                htmlEl.classList.remove('overflow-hidden');
                bodyEl.classList.remove('overflow-hidden');
                modalSingleJobOffersContent.innerHTML = '';
                document.removeEventListener('click', closeSingleJobOffersModalListener);
                closeSingleJobOffersModalListener = null;

                setTimeout(function() {
                    modalSingleJobOffers.style.display = 'none';
                }, 300);
            }
        }

        back.addEventListener('click', function() {
            modalSingleJobOffers.classList.remove('show');
                
                modalSingleJobOffersContent.innerHTML = '';
                document.removeEventListener('click', closeSingleJobOffersModalListener);
                closeSingleJobOffersModalListener = null;

                if(!modalJobOffers.classList.contains('show')) {
                    modalBackdrop.classList.remove('active');
                    htmlEl.classList.remove('overflow-hidden');
                    bodyEl.classList.remove('overflow-hidden');
                }

                setTimeout(function() {
                    modalSingleJobOffers.style.display = 'none';
                }, 300);
        });
    });
}