import axios from "axios";
import Cookies from 'js-cookie';

export function initSalonCptScripts() {
    const searchboxSubmit = document.querySelector('.searchbox__submit');
    const pageStoreLocatorBtnSub = document.querySelector('.pageStoreLocator__btnSub');

    if (searchboxSubmit && pageStoreLocatorBtnSub) {
        pageStoreLocatorBtnSub.addEventListener('click', function () {
            searchboxSubmit.click();
        });
    }

}