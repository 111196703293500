export function navInit() {

  const arrowRight = `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5757 11.5757C14.81 11.3414 15.1899 11.3414 15.4243 11.5757L21.4243 17.5757C21.6586 17.81 21.6586 18.1899 21.4243 18.4243L15.4243 24.4243C15.1899 24.6586 14.81 24.6586 14.5757 24.4243C14.3414 24.1899 14.3414 23.81 14.5757 23.5757L20.1515 18L14.5757 12.4243C14.3414 12.1899 14.3414 11.81 14.5757 11.5757Z" fill="#272726"/>
  </svg>
  `;
  const menuBtn = document.querySelector('.header__menu-toggle');
  const menuSide = document.querySelector('.menu_side');
  const body = document.querySelector('body');
  const sideMenuClose = document.querySelector('.menu_side__header .close');
  const elements = document.querySelectorAll('.menu_mobile li.menu-item-has-children');
  const sideMenuBack = document.querySelector('.menu_side__header .back');
  const sideMenuHeader = document.querySelector('.menu_side__header');
  const sideMenuBackground = document.querySelector('.menu_side__background');
  const openMenuSubHome = document.querySelector('.openMenuSubHome');
  const openMenuSubIndustry = document.querySelector('.openMenuSubIndustry');
  const menuSideTitle = document.querySelector('.menu_side__title');
  const openMenuListHome = document.querySelector('.openMenuListHome');
  const openMenuListIndustry = document.querySelector('.openMenuListIndustry');


  if (menuBtn && menuSide) {
    menuBtn.addEventListener('click', () => {
      menuSide.classList.toggle('is-active');
      body.classList.add('overflow-hidden');
    })
  }

  const closeMenu = () => {
    menuSide.classList.remove('is-active');
    body.classList.remove('overflow-hidden');
  }

  sideMenuClose.addEventListener("click", closeMenu)

  sideMenuBackground.addEventListener('click', closeMenu);

  sideMenuBack.addEventListener("click", (e) => {
    sideMenuHeader.classList.remove('open');
    menuSideTitle.textContent = 'Menu';
    elements.forEach(element => {
      if (element.classList.contains('open')) {
        element.classList.remove('open');
      }
    })
  })

  elements.forEach(element => {
    const item = element.querySelector('a');

    const spanArrow = document.createElement('span');
    spanArrow.setAttribute('class', 'arrow-right');
    spanArrow.innerHTML = arrowRight;
    item.appendChild(spanArrow);

    element.addEventListener("click", (e) => {
      menuSideTitle.textContent = element.querySelector('a').textContent;
      element.classList.add('open');
      sideMenuHeader.classList.add('open');

    })

  })

  if (openMenuListHome && openMenuListIndustry) {
    const handleClickSubmenu = (openMenu, openMenuList) => {
      if (openMenu) {
        // const openMenuLink = document.querySelector(`${openMenu} > a`);
        // const item = document.querySelector(`${openMenu} a`);

        document.querySelector(openMenu).addEventListener('click', (e) => {
          e.preventDefault();
          menuSide.classList.add('is-active');
          body.classList.add('overflow-hidden');
          openMenuList.click();
        });
      }
    };

    handleClickSubmenu('.openMenuListHome', openMenuSubHome);
    handleClickSubmenu('.openMenuListIndustry', openMenuSubIndustry);
  }

}