import axios from "axios";
import Cookies from 'js-cookie';

export function initChooseSalon() {
    document.addEventListener('DOMContentLoaded', function () {
        const selectedSalonId = 'selectedSalonId';
        const chooseSalon = document.querySelector('.chooseSalon');
        const chooseSalonClose = document.querySelector('.chooseSalon__close');
        const chooseSalonBackground = document.querySelector('.chooseSalon__background');
        const chooseSalonLoader = document.querySelector('.chooseSalon__loader');
        const htmlTag = document.querySelector('html');
        const bodyTag = document.querySelector('body');
        const salonList = document.querySelector('.salon-list');
        const searchResults = document.querySelector('.chooseSalon__list');
        const chooseSalonShowAll = document.querySelector('.chooseSalon__showAll');
        const chooseSalonShowBtns = document.querySelector('.chooseSalon__btns');
        const chooseSalonSearch = document.querySelector('.chooseSalon__search');
        const chooseSalonFind = document.querySelector('.chooseSalon__findSalon');
        const chooseSalonSelected = document.querySelector('.chooseSalon__selected');
        const chooseSalonShowSelected = document.querySelector('.chooseSalon__showSelected');
        const nearestShowroom = document.querySelector('.nearest-showroom');
        const chooseSalonLoadMore = document.querySelector('.chooseSalon__load-more');
        const chooseSalonUserLocation = document.querySelector('.chooseSalon__userLocation');
        const taxonomyCitySalons = document.querySelectorAll('.city-salon');


        if (taxonomyCitySalons.length > 0) {
            taxonomyCitySalons.forEach(salon => {

                salon.addEventListener('click', () => {
                    console.log('click');
                    const id = salon.getAttribute('data-id');
                    console.log('id', id);

                    Cookies.set(selectedSalonId, id, {
                        expires: 90
                    });
                })
            })
        }



        const showLoading = () => {
            chooseSalonLoader.classList.add('active');
        }

        const hideLoading = () => {
            chooseSalonLoader.classList.remove('active');
        }

        const successCallbackCurrentPosition = (position) => {
            hideLoading();
            const latitude = parseFloat(position.coords.latitude);
            const longitude = parseFloat(position.coords.longitude);
            const targetLat = latitude;
            const targetLon = longitude;
            const searchLimit = 5;
            const maxDistance = 100; // Maximum distance in kilometers

            const elementsList = salonsFullList;
            const closestPostcodes = findClosestPostcodes(targetLat, targetLon, elementsList, searchLimit, maxDistance);

            closestPostcodes.forEach(element => {
                const elem = `<div class="search-item search-item--salon ${element.type_slug}" data-id="${element.id}">
                                    <div class="search-item__wrap">
                                        <div class="search-item__type">${element.type}</div>
                                        <p class="search-item__title">
                                            ${element.name}
                                        </p>
                                        <p>${element.address}</br>${element.postCode} ${element.city}</p>
                                    </div>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4091 4.57573C10.6434 4.34142 11.0233 4.34142 11.2576 4.57573L16.2576 9.57573C16.3701 9.68825 16.4333 9.84086 16.4333 9.99999C16.4333 10.1591 16.3701 10.3117 16.2576 10.4243L11.2576 15.4243C11.0233 15.6586 10.6434 15.6586 10.4091 15.4243C10.1747 15.1899 10.1747 14.81 10.4091 14.5757L14.3848 10.6H4.16665C3.83528 10.6 3.56665 10.3314 3.56665 9.99999C3.56665 9.66862 3.83528 9.39999 4.16665 9.39999H14.3848L10.4091 5.42426C10.1747 5.18994 10.1747 4.81004 10.4091 4.57573Z" fill="#272726" />
                                    </svg>
                                </div>`;
                searchResults.innerHTML += elem;
            });
        };

        const errorCallbackCurrentPosition = (error) => {
            const errorMessage = "<p>Brak zgody na lokalizację.</p>";
            searchResults.innerHTML = errorMessage;
            hideLoading();
        };



        chooseSalonUserLocation.addEventListener('click', () => {
            searchResults.innerHTML = '';
            chooseSalonLoadMore.classList.add('hide');
            showLoading();
            navigator.geolocation.getCurrentPosition(successCallbackCurrentPosition, errorCallbackCurrentPosition);
        })

        let currentPage = 1;
        chooseSalonLoadMore.addEventListener('click', function () {
            currentPage++;
            showLoading();
            axios.post(`/wp-json/krishome/v1/salon_list`, {
                paged: currentPage
            })
                .then(function (response) {
                    const res = response.data.html;
                    searchResults.innerHTML += res;

                    if (response.data.list_end) {
                        chooseSalonLoadMore.classList.add('hide');
                    } else {
                        chooseSalonLoadMore.classList.remove('hide');
                    }

                    hideLoading();
                })
                .catch(function (error) {
                    console.error(error);
                });

        });

        const selectedSalondata = () => {
            const cookieValue = Cookies.get(selectedSalonId);

            if (cookieValue) {
                axios.post(`/wp-json/krishome/v1/salon_single`, {
                    id: cookieValue
                }).then(response => {
                    chooseSalonSelected.innerHTML = response.data.html;
                    salonList.innerHTML = response.data.salon_city;
                    nearestShowroom.innerHTML = response.data.html_footer;
                    chooseSalonFind.classList.add('hide');
                    chooseSalonShowBtns.classList.add('hide');
                    chooseSalonSelected.classList.remove('hide');
                    searchResults.classList.add('hide');
                    chooseSalonLoader.classList.remove('active');
                    chooseSalon.querySelector('.chooseSalon__wrap').classList.remove('hide');
                });
            }
        }

        const setSelectedSalonId = (id) => {
            Cookies.set(selectedSalonId, id, {
                expires: 90
            });
            searchResults.innerHTML = "";
            chooseSalonFind.classList.add('hide');
            chooseSalonLoadMore.classList.add('hide');
            selectedSalondata();
        }

        const getSalons = () => {
            searchResults.innerHTML = "";
            // chooseSalon.querySelector('.chooseSalon__wrap').classList.add('hide');
            chooseSalonLoader.classList.add('active');
            axios.post(`/wp-json/krishome/v1/salon_list`).then(response => {
                searchResults.innerHTML = response.data.html;
                chooseSalonLoader.classList.remove('active');
                // chooseSalon.querySelector('.chooseSalon__wrap').classList.remove('hide');
                if (response.data.list_end) {
                    chooseSalonLoadMore.classList.add('hide');
                } else {
                    chooseSalonLoadMore.classList.remove('hide');
                }

            });
        }

        const modalToggle = function () {
            chooseSalon.classList.toggle('open');
            htmlTag.classList.toggle('overflow-hidden');
            bodyTag.classList.toggle('overflow-hidden');
        }
        const openModal = function () {
            modalToggle();
        }

        const closeModal = () => {
            modalToggle();
        }

        const showSelectedSalon = () => {
            chooseSalonFind.classList.add('hide');
            chooseSalonShowBtns.classList.add('hide');
            searchResults.classList.add('hide');
            chooseSalonSelected.classList.remove('hide');
        }

        chooseSalonShowSelected.addEventListener('click', showSelectedSalon);

        selectedSalondata();

        if (salonList) {
            salonList.addEventListener('click', openModal);
            chooseSalonClose.addEventListener('click', closeModal);
            chooseSalonBackground.addEventListener('click', closeModal);
            document.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    closeModal();
                }
            });
        }

        if (chooseSalonSearch) {
            const chooseSalonSearchInput = chooseSalonSearch.querySelector('.chooseSalon__searchField');

            // chooseSalonSearchInput.addEventListener('input', (event) => {
            //     const inputValue = chooseSalonSearchInput.value;

            //     if (inputValue.length === 2 && inputValue.charAt(2) !== '-') {
            //         chooseSalonSearchInput.value = inputValue.slice(0, 2) + '-' + inputValue.slice(2);
            //     } else if (inputValue.length === 3 && inputValue.charAt(2) === '-') {
            //         if (event.inputType === 'deleteContentBackward' || event.inputType === 'deleteContentForward') {
            //             chooseSalonSearchInput.value = inputValue.slice(0, 2) + inputValue.slice(3);
            //         }
            //     }
            // });

            chooseSalonSearch.addEventListener('submit', (event) => {
                event.preventDefault();
                searchResults.innerHTML = '';
                chooseSalonLoadMore.classList.add('hide');

                if (chooseSalonSearchInput.value.length > 3) {
                    const errorMessage = "<p>Brak wyników lub błędny kod pocztowy.</p>";
                    const searchValue = chooseSalonSearchInput.value;
                    const apiUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${searchValue}&countrycodes=PL`;

                    chooseSalonLoader.classList.add('active');

                    axios.get(apiUrl)
                        .then(response => {
                            const data = response.data;
                            if (data.length > 0) {
                                const latitude = parseFloat(data[0].lat);
                                const longitude = parseFloat(data[0].lon);
                                const targetLat = latitude;
                                const targetLon = longitude;
                                const searchLimit = 5;
                                const maxDistance = 50; // Maximum distance in kilometers
                                const elementsList = salonsFullList;
                                const closestPostcodes = findClosestPostcodes(targetLat, targetLon, elementsList, searchLimit, maxDistance);

                                closestPostcodes.forEach(element => {
                                    const elem = `<div class="search-item search-item--salon ${element.type_slug}" data-id="${element.id}">
                                    <div class="search-item__wrap">
                                        <div class="search-item__type">${element.type}</div>
                                        <p class="search-item__title">
                                            ${element.name}
                                        </p>
                                        <p>${element.address}</br>${element.postCode} ${element.city}</p>
                                    </div>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4091 4.57573C10.6434 4.34142 11.0233 4.34142 11.2576 4.57573L16.2576 9.57573C16.3701 9.68825 16.4333 9.84086 16.4333 9.99999C16.4333 10.1591 16.3701 10.3117 16.2576 10.4243L11.2576 15.4243C11.0233 15.6586 10.6434 15.6586 10.4091 15.4243C10.1747 15.1899 10.1747 14.81 10.4091 14.5757L14.3848 10.6H4.16665C3.83528 10.6 3.56665 10.3314 3.56665 9.99999C3.56665 9.66862 3.83528 9.39999 4.16665 9.39999H14.3848L10.4091 5.42426C10.1747 5.18994 10.1747 4.81004 10.4091 4.57573Z" fill="#272726" />
                                    </svg>
                                </div>`;

                                    searchResults.innerHTML += elem;
                                });

                            } else {
                                searchResults.innerHTML = errorMessage;
                            }
                            chooseSalonLoader.classList.remove('active');
                            // chooseSalonLoadMore.classList.add('hide');
                        })
                        .catch(error => {
                            console.error("Error:", error);
                            chooseSalonLoader.classList.remove('active');
                            searchResults.innerHTML = errorMessage;
                        });
                }
            });
        }

        if (chooseSalonShowAll) {
            chooseSalonShowAll.addEventListener('click', getSalons);
        }

        jQuery(document.body).on('click', '.search-item--salon', function (event) {
            const salonId = jQuery(this).data("id");
            setSelectedSalonId(salonId);
        });

        jQuery(document.body).on('click', '.selected-salon__change', function (event) {
            chooseSalonSelected.classList.add('hide');
            chooseSalonShowBtns.classList.remove('hide');
            chooseSalonFind.classList.remove('hide');
            chooseSalonShowSelected.classList.remove('hide');
            searchResults.classList.remove('hide');
        });

        function calculateDistance(lat1, lon1, lat2, lon2) {
            // Convert coordinates to radians
            const lat1Rad = toRadians(lat1);
            const lon1Rad = toRadians(lon1);
            const lat2Rad = toRadians(lat2);
            const lon2Rad = toRadians(lon2);

            // Radius of the Earth in kilometers
            const radius = 6371;

            // Haversine formula
            const dlat = lat2Rad - lat1Rad;
            const dlon = lon2Rad - lon1Rad;
            const a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dlon / 2) ** 2;
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const distance = radius * c;

            return distance;
        }

        function toRadians(degrees) {
            return degrees * (Math.PI / 180);
        }
        function findClosestPostcodes(targetLat, targetLon, elementsList, searchLimit, maxDistance) {
            let closestPostcodes = [];

            for (const element of elementsList) {
                const type = element.type;
                const type_slug = element.type_slug;
                const elementName = element.name;
                const id = element.id;
                const address = element.details.address;
                const postCode = element.details.postcode;
                const city = element.details.city;
                const elementLat = element.details.lat;
                const elementLon = element.details.lng;
                const order = element.order;


                const distance = calculateDistance(targetLat, targetLon, elementLat, elementLon);

                if (distance <= maxDistance) {
                    closestPostcodes.push({ id: id, type: type, address: address, postCode: postCode, city: city, name: elementName, distance: distance, type_slug: type_slug, order: order });
                }
            }

            closestPostcodes.sort((a, b) => a.distance - b.distance).sort((a, b) => a.order.localeCompare(b.order));
            closestPostcodes = closestPostcodes.slice(0, searchLimit);

            return closestPostcodes;
        }


    });
}